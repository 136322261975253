import { Injectable, Inject } from '@angular/core';
import { ApiService } from '../helper/services/api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MSParkingMapping, MovieSchedule, Show, Showproperties } from '../location-movie-schedule/list/movieScheduleData';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MovieService } from '../movies/movie.service';
import { SalesOrder } from '../helper/models/SalesOrder';
@Injectable({
	providedIn: 'root'
})
export class MovieScheduleService extends ApiService {
	MovieSchedule: MovieSchedule[] = [];
	baseUrl: string;
	constructor(private http: HttpClient, @Inject("BASE_URL") base: string) {
		super(base);
		this.baseUrl = base;
		this.ApiEndPoint = base + "api/MovieSchedule";
	}

	//getMovieSchedules(): Observable<Array<MovieSchedule>> {
	//	return this.http.get<Array<MovieSchedule>>(this.ApiEndPoint)
	//		.pipe(catchError(this.errorHandler));
	//}

	//geMovieSchedulesByLocationId(): Observable<Array<MovieSchedule>> {
	//	return this.http.get<Array<MovieSchedule>>(this.ApiEndPoint + "/GetByLocationId")
	//		.pipe(catchError(this.errorHandler));
	//}

	geMovieSchedulesByLocationIdAndDateRange(startDate: string, endDate: string): Observable<Array<MovieSchedule>> {
		return this.http.get<Array<MovieSchedule>>(this.ApiEndPoint + "/GetByDateAndLocationId/" + startDate + "/" + endDate)
			.pipe(catchError(this.errorHandler));
	}

  checkExistsShowsForCopiedDays(MV: Array<MovieSchedule>): Observable<Array<MovieSchedule>> {
    return this.http.post<Array<MovieSchedule>>(this.ApiEndPoint +"/CheckExistsShowsForCopiedDays", MV)
      .pipe(catchError(this.errorHandler));
  }

	addMovieSchedules(MV: Array<MovieSchedule>): Observable<Array<MovieSchedule>> {
		return this.http.post<Array<MovieSchedule>>(this.ApiEndPoint, MV)
			.pipe(catchError(this.errorHandler));
	}

  addtempMovieSchedules(MV: Array<MovieSchedule>): Observable<Array<MovieSchedule>> {
    return this.http.post<Array<MovieSchedule>>(this.ApiEndPoint + "/PostTempMovieSchedules", MV)
      .pipe(catchError(this.errorHandler));
  }

	editMovieSchedules(MV: Array<MovieSchedule>): Observable<Array<MovieSchedule>> {
		return this.http.put<Array<MovieSchedule>>(this.ApiEndPoint, MV)
			.pipe(catchError(this.errorHandler));
	}

	deleteMovieSchedules(MV: Array<MovieSchedule>): Observable<Array<MovieSchedule>> {
		let options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			}),
			body: MV
		};
		return this.http.delete<Array<MovieSchedule>>(this.ApiEndPoint, options)
			.pipe(catchError(this.errorHandler));
	}
	getSalesOrderByShowID(showID) {
		return this.http.get<Array<SalesOrder>>(this.baseUrl + 'api/SalesOrder/GetByShowId/' + showID).pipe(catchError(this.errorHandler));
	}
	postGetSeatsCounts(MovieIds): Observable<Array<String>> {
		return this.http.post<Array<String>>("/api/SalesOrder/GetShowTicketCount", MovieIds).pipe(catchError(this.errorHandler));
	}
	GetAllShowPropery(): Observable<Array<Showproperties>> {
		return this.http.get<Array<Showproperties>>("api/Showproperties")
			.pipe(catchError(this.errorHandler));
  }
  getcommonStaticContent(): Observable<any> {
    return this.http.get<any>(this.ApiEndPoint +"/GetCommonStaticContent")
      .pipe(catchError(this.errorHandler));
  }

  // Get All Temporary shows Data
  getTempMovieSchedulesByLocationId(): Observable<Array<MovieSchedule>> {
    return this.http.get<Array<MovieSchedule>>(this.ApiEndPoint + "/GetTempMovieSchedulesByLocationId")
      .pipe(catchError(this.errorHandler));
  }

  updateMovieSchedulesStatusByLocationIdAndDateRange(startDate: string, endDate: string, status): Observable<Array<MovieSchedule>> {
    return this.http.patch<Array<MovieSchedule>>(this.ApiEndPoint + "/UpdateStatusByDateAndLocationId/" + startDate + "/" + endDate + "/" + status, null)
      .pipe(catchError(this.errorHandler));
  }
  addMSParkingMapping(MV: Array<MSParkingMapping>): Observable<Array<MSParkingMapping>> {
    return this.http.post<Array<MSParkingMapping>>(this.ApiEndPoint +"/PostMSParkingMapping", MV)
      .pipe(catchError(this.errorHandler));
  }
  deleteMSParkingMapping(MV: Array<MSParkingMapping>): Observable<Array<MSParkingMapping>> {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: MV
    };
    return this.http.delete<Array<MSParkingMapping>>(this.ApiEndPoint+"/deleteMSParkingMapping", options)
      .pipe(catchError(this.errorHandler));
  }
  getParkingLots(): Observable<any> {
    return this.http.get<any>(this.ApiEndPoint + "/getAllParkingLot")
      .pipe(catchError(this.errorHandler));
  }
  getMSParkingMapping(movieID: string, showID: string): Observable<any> {
    return this.http.get(this.ApiEndPoint + "/getMSParkingMapping/" + movieID + '/' + showID)
      .pipe(catchError(this.errorHandler));
  }
  getMSParkingMappingByShowIds(showIDs: string[]): Observable<any> {
    return this.http.post<Array<MSParkingMapping>>(this.ApiEndPoint + "/GetMSParkingMappingByShowIds" , showIDs)
      .pipe(catchError(this.errorHandler));
  }
}
