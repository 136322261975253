import { Component, Inject } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { CurrentUserService } from '../helper/services/current-user.service';
import { LocationsService } from '../locations/locations.service';
import { OverrideAccessService } from '../helper/services/overrideaccess.service';
import { POSService } from '../pos/pos.service';
import { CompanyService } from '../company/company.service';
import { EmployeeService } from '../employee/employee.service';
import { EmployeeOverrideAccess } from '../employee/employee';
import { DialogService } from '../dialog.service';
import Swal from 'sweetalert2';


// This is the service used to ensure the location navigation is evaluated.
// While loading this component its decided that where we need to end the landing page.


@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})

export class NavMenuComponent {
  curuser: Curuser = { DisplayName: '', CompanyId: '', Email: '', Pin: '', AvatarImageURL: '' };
  //  support: any = {
  //    phoneNumber: "+1 774-490-9000" ,
  //emailId: "support@cinema360.org"}
  support: any = {
    phoneNumber: "",
    emailId: ""
  };

  adminAccess = false;
  hasCompanyAccess = false;
  hasLocation = false;
  hasMovies = false;
  hasEmployeeFeature = false;
  hasGiftCard = false;
  hasMerchants = false;
  hasReport = false;
  hasCompany = false;
  hasOverrideAcess = false;
  addLocationAccess: boolean = false;
  hasPromoteMovies = false;

  public username: string;
  isExpanded = false;
  employeedata: any;
  employeepin: any;
  notificationOn: boolean;
  userReleaseDate: any;
  //reports
  arrowReport: string = "▼";
  reportDropdown: boolean = false;

  //company
  arrowCompany: string = "▼";
  companyDropdown: boolean = false;
  AuthorizeQRData: any = null;
  //Override access
  empOverrideAccess: EmployeeOverrideAccess = new EmployeeOverrideAccess();

  locationId: string;
  companyId: string;

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  constructor(
    http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private router: Router,
    private currentUserService: CurrentUserService,
    private locationService: LocationsService,
    private overrideService: OverrideAccessService,
    private activeRoute: ActivatedRoute,
    private posService: POSService,
    private clientCompanyService: CompanyService,
    private employeeService: EmployeeService,
    private dialogservice : DialogService  ) {

    http.get<Curuser>(baseUrl + 'api/currrentuser').subscribe(result => {
      this.curuser = result;
      localStorage.setItem("isLoggedin", "true");
     /// this.getpromotemoviesAccess();
    }, error => this.router.navigate(['/']));

    this.dialogservice.testComponent$.subscribe(res => {
      this.EnableOrDisableWindowsIcon = res;
    })
    this.dialogservice.testComponentD$.subscribe(res => {
      this.EnableOrDisableWindowsIconD = res;
    })
  }
  EnableOrDisableWindowsIcon: boolean = false;
  EnableOrDisableWindowsIconD: boolean = false;
  async ngOnInit() {
    this.currentUserService.getContextLocationId().subscribe(contextLocation => {
      this.locationId = contextLocation;
    });
    this.clientCompanyService.getCompanyIdByContext().subscribe(contextcompany => {
      this.companyId = contextcompany.Company_ID;
    });
    //this.currentUserService.getFeature('Company', 'read').subscribe(hasAccess => {
    //  this.hasCompanyAccess = hasAccess;
    //});

    // TODO : Anisha this is temporary, look for the function call of getCompanyFeature method invocation.
    // Currently the method call from li item looping through. Investigate.
    this.currentUserService.getCompanyFeature('Locations', 'read').subscribe(hasAccess => {
      this.hasLocation = hasAccess;
    });

    this.currentUserService.getCompanyFeature('Movies', 'read').subscribe(hasAccess => {
      this.hasMovies = hasAccess;
    });

    this.currentUserService.getCompanyFeature('EmployeeFeatures', 'read').subscribe(hasAccess => {
      this.hasEmployeeFeature = hasAccess;
    });

    this.currentUserService.getCompanyFeature('GiftCards', 'read').subscribe(hasAccess => {
      this.hasGiftCard = hasAccess;
    });

    //Merchants
    this.currentUserService.getCompanyFeature('Merchants', 'read').subscribe(hasAccess => {
      this.hasMerchants = hasAccess;
    });

    //Reports
    this.currentUserService.getCompanyFeature('Merchants', 'read').subscribe(hasAccess => {
      this.hasReport = hasAccess;
    });

    //Company
    this.currentUserService.getCompanyFeature('Merchants', 'read').subscribe(hasAccess => {
      this.hasCompany = hasAccess;
    });
    this.currentUserService.getCompanyFeature('Promote Movies', 'read').subscribe(hasAccess => {
      this.hasPromoteMovies = hasAccess;
    });
    this.currentUserService.getAdminAccess().subscribe(hasAccess => {
      this.adminAccess = hasAccess;
      this.checkLocCount();
    });

    this.clientCompanyService.getsupportinfo().subscribe((res: any) => {
      //console.log("hello", res);
      this.support.phoneNumber = res[0].PhoneNumber;
      this.support.emailId = res[0].EmailId;
    });

    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.getPin();
      }
    });

    //this.releasenotes();
  }

  /*releasenotes() {
    this.currentUserService.getreleasenotes().subscribe(res => {
      this.userReleaseDate = res[0].ReleaseDate;

      if (localStorage.getItem('notificationdate') == null) {
        localStorage.setItem('notificationdate', res[0].ReleaseDate);
        this.notificationOn = true;
      }
      else if (localStorage.getItem('notificationdate') == res[0].ReleaseDate) {
        this.notificationOn = false;
      }
      else {
        this.notificationOn = true;
      }
    })
  }
  onrelease() {
    this.notificationOn = false;
    localStorage.setItem('notificationdate', this.userReleaseDate);
  }*/

  /*report section starts*/

  getpromotemoviesAccess() {
    // promote movies
    this.currentUserService.getpromotemoviesAccess().subscribe(hasAccess => {
      //this.hasPromoteMovies = hasAccess;
      if (hasAccess && this.curuser != null && this.curuser.Email) {
        let roleName = "Promote Movies";
        this.employeeService.GetByEmail(this.curuser.Email).subscribe(emp => {
          if (emp != null && emp.SelectedCompanyRoles.length > 0) {
            let roleList = emp.SelectedCompanyRoles.filter(x => x.CompanyId == this.curuser?.CompanyId && x.CompanyRoleName.toLowerCase() == roleName.toLowerCase());
            if (roleList.length > 0 && roleList[0].Read) {
              this.hasPromoteMovies = true;
            }
          }
        });
      }
    });
  }

  onReport() {
    if (this.reportDropdown == false) {
      this.arrowReport = "▲";
      this.reportDropdown = true;
    }
    else {
      this.arrowReport = "▼";
      this.reportDropdown = false;
    }
  }

  /*report section ends*/
  /*company section starts*/
  onCompany() {
    if (this.companyDropdown == false) {
      this.arrowCompany = "▲";
      this.companyDropdown = true;
    }
    else {
      this.arrowCompany = "▼";
      this.companyDropdown = false;
    }
  }

  /*company section ends*/
  async managesessionlogo() {
    sessionStorage.removeItem("location");
    await this.clientCompanyService.getCompanyLogoUrl();
  }

  getPin() {
    console.log(this.router.url);
    this.employeeService.GetByEmail(this.curuser.Email).subscribe(res => {

      this.employeepin = res.Pin;
    })
    this.currentUserService.getFeature('OverrideAccess', 'read').subscribe(async hasAccsess => {
      this.hasOverrideAcess = hasAccsess;
      if (this.hasOverrideAcess) {
        //this.empOverrideAccess = await this.employeeService.getEmployeeOverrideAccess().toPromise();

        this.overrideService.getOverridePin().subscribe(pin => {
          //if (pin != "0" && (this.empOverrideAccess.Read == 1 || this.empOverrideAccess.Write == 1 || this.empOverrideAccess.GPO == 1))
          if (pin != "0")
            this.curuser.Pin = pin;
          else
            this.curuser.Pin = 'N/A';
        });
      } else {
        this.curuser.Pin = 'N/A';
      }
    });
  }

  getQrCodeAuthorizeData() {
    let autho = {
      CompanyId: this.companyId,
      LocationId: this.locationId
    };
    this.clientCompanyService.genrateAuthorizeExternalDevicePin(autho).subscribe(responce => {
      console.log("responce Pin ", responce);
      if (responce && responce.Image) {
        this.AuthorizeQRData = 'data:image/png;base64,' + responce.Image;
      } else {
        Swal.fire('', 'Please upload the location or company logo to continue', 'error')
      }
     
    });

  }
  checkLocCount() {
    // Validate the routing only if location id is not set. If we dont have this check then any child component page referesh will take you to the locations or location page.
    if (this.adminAccess)
      return;
    this.currentUserService.getLocationIds().subscribe(locationIds => {
      this.currentUserService.getCompanyFeature('Locations', 'write').subscribe(hasAccess => {
        this.addLocationAccess = hasAccess;
        if (locationIds === undefined || locationIds === null) {
          this.router.navigate(['/']); // user has no location id's claim
        } else
          if (locationIds.length == 0) { // super user return. It should be handled on the first return though.
            this.router.navigate(['/locations']);
          }
          else {
            this.currentUserService.getContextLocationId().subscribe(contextLocation => {
              if (contextLocation === undefined || contextLocation == null || contextLocation == '') {
                if (locationIds.length > 1) {
                  this.router.navigate(['/locations']);
                }
                else if (locationIds.length === 1) {
                  if (this.addLocationAccess) {
                    this.router.navigate(['/locations']);
                  }
                  else {
                    // simply call the location service get by location id which sets the necessary claims for this location context.
                    this.locationService.getLocationByID(locationIds[0]).subscribe(location => {
                    });
                    this.router.navigate(['/locations/setting/' + locationIds[0]]); // TODO remove the location id usage when cleaning up the id
                  }
                }
                else { // You never should reach this.
                  this.router.navigate(['/']);
                }
              }
            }, error => this.router.navigate(['/']));
          }
      }, error => this.router.navigate(['/']));
    }, error => this.router.navigate(['/']));
  }

  async getCompanyFeature(featureName: string): Promise<boolean> {
    var hasFeature = false;
    if (featureName !== undefined)
      hasFeature = await this.currentUserService.getCompanyFeature(featureName, 'read').toPromise().then(result => result);
    return hasFeature;
  }
  downloadApp() {
    if (this.EnableOrDisableWindowsIconD) {
      this.currentUserService.getWinAppUrl().subscribe((result: any) => {
        const url = result;
        const a = document.createElement('a');
        a.href = url;
        a.download = "C360WApps.zip";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    }
  }
}

interface Curuser {
  DisplayName: string;
  Email: string;
  CompanyId: string;
  Pin: string;
  AvatarImageURL: string;
}

