import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorMessagesService {

  constructor() { }

  public globalErrorList = [
    {
      "ID": "0",
      "Title": "System Error",
      "Message": "An error occurred.  Please Sign off and Sign in again or close the browser and reopen the application.  If none of the above works, please contact support."
    },
    {
      "ID": "1",
      "Title": "Search Error",
      "Message": "No records found.  Please scan QR Code again"
    },
    {
      "ID": "2",
      "Title": "Validation error",
      "Message": "You maxed at 10!! Choose something else from the available items"
    },
    {
      "ID": "3",
      "Title": "Validation error",
      "Message": "This item is out of stock! Please select another item."
    },
    {
      "ID": "4",
      "Title": "Validation error",
      "Message": "Please verify your card. Swipe again or use another card"
    },
    {
      "ID": "5",
      "Title": "Notification",
      "Message": "Your session will expire automatically in 04 min 56 seconds. Please click on Continue to extend your session."
    },
    {
      "ID": "6",
      "Title": "Validation error",
      "Message": "GiftCard Not Found\nPlease enter a valid Gift Card number"
    },
    {
      "ID": "6",
      "Title": "Validation error",
      "Message": "GiftCard Not Found\nPlease enter a valid Gift Card number"
    },
    {
      "ID": "7",
      "Title": "Validation error",
      "Message": "Please add tickets first!"
    },
    {
      "ID": "8",
      "Title": "Validation error",
      "Message": "Cannot reduce quantity of concession items that are part of combo.  "
    },
    {
      "ID": "9",
      "Title": "Validation error",
      "Message": "Sorry! These tickets are already printed. Please contact the Manager for further assistance"
    },
    {
      "ID": "10",
      "Title": "Validation error",
      "Message": "No ticket found for the information provided"
    },
    {
      "ID": "11",
      "Title": "Validation error",
      "Message": "You can only book a maximum of 10 tickets per transaction. If you would still like to pick up more tickets, please make another transaction."
    },
    {
      "ID": "12",
      "Title": "Validation error",
      "Message": "Only <X> \"Combo ticket\" available.  Please choose more from other combo options"
    },
    {
      "ID": "13",
      "Title": "Validation error",
      "Message": "\"Combo ticket name\" is out of stock! Please choose another combo item"
    },
    {
      "ID": "14",
      "Title": "Validation error",
      "Message": "Invalid CVV.  Please enter valid 3 digit CVV"
    },
    {
      "ID": "15",
      "Title": "Validation error",
      "Message": "Please swipe card to process payment"
    },
    {
      "ID": "16",
      "Title": "Validation error",
      "Message": "Invalid credit card number.  Choose another card or change payment mode"
    },
    {
      "ID": "17",
      "Title": "Validation error",
      "Message": "No records found. Please verify confirmation code and try again"
    },
    {
      "ID": "18",
      "Title": "Validation error",
      "Message": "Are you sure you want to swap <X> to <Y>?"
    },
    {
      "ID": "19",
      "Title": "Information",
      "Message": "Are you sure you don't want to hold this seat/s anymore?"
    },
    {
      "ID": "20",
      "Title": "Validation error",
      "Message": "Sorry!  Please try to refund after 24 hours from the transcation time to refund to card. Or Use cash mode for refund."
    },
    {
      "ID": "21",
      "Title": "Validation error",
      "Message": "Please enter valid amount"
    },
    {
      "ID": "22",
      "Title": "Validation error",
      "Message": "Sorry !! Pin does not match"
    },
    {
      "ID": "23",
      "Title": "Validation error",
      "Message": "Please fill all mandatory fields"
    },
    {
      "ID": "24",
      "Title": "Validation error",
      "Message": "Wastage entry updated"
    },
    {
      "ID": "25",
      "Title": "Validation error",
      "Message": "Please enter valid confirmation code"
    },
    {
      "ID": "26",
      "Title": "Validation error",
      "Message": "No tickets found to swap seats!  Please enter valid confirmation code"
    },
    {
      "ID": "27",
      "Title": "Validation error",
      "Message": "Please enter last four digits of the card number used for transaction"
    },
    {
      "ID": "28",
      "Title": "Validation error",
      "Message": "Please enter First Name and Last Name"
    },
    {
      "ID": "29",
      "Title": "Validation error",
      "Message": "Please input valid data"
    },
    {
      "ID": "30",
      "Title": "Validation error",
      "Message": "No records found. Please enter valid phone number"
    },
    {
      "ID": "31",
      "Title": "Validation error",
      "Message": "No records found.  Please enter valid Email Id"
    },
    {
      "ID": "32",
      "Title": "Validation error",
      "Message": "Hold Seats request updated"
    },
    {
      "ID": "33",
      "Title": "Validation error",
      "Message": "Cannot complete this request as no seats were held for this movie."
    },
    {
      "ID": "34",
      "Title": "Search Error",
      "Message": "No Movies Found"
    },
    {
      "ID": "35",
      "Title": "Validation error",
      "Message": "Please enter valid confirmation code and print"
    },
    {
      "ID": "36",
      "Title": "Validation error",
      "Message": "Please enter First Name and Last Name provided at the time of booking"
    },
    {
      "ID": "37",
      "Title": "Validation error",
      "Message": "This GiftCard has insufficient balance.\nPlease use other payment options."
    },
    {
      "ID": "38",
      "Title": "Validation error",
      "Message": "Order placed successfully"
    },
    {
      "ID": "39",
      "Title": "Validation error",
      "Message": "Card Holder Name is Required"
    },
    {
      "ID": "40",
      "Title": "Validation error",
      "Message": "Please enter name as it appears on the card"
    },
    {
      "ID": "41",
      "Title": "Validation error",
      "Message": "Please enter valid card number"
    },
    {
      "ID": "42",
      "Title": "Validation error",
      "Message": "Invalid card number! Enter a valid card number!"
    },
    {
      "ID": "43",
      "Title": "Validation error",
      "Message": "Card Number is Required!"
    },
    {
      "ID": "44",
      "Title": "Validation error",
      "Message": "Enter Month"
    },
    {
      "ID": "45",
      "Title": "Validation error",
      "Message": "Incorrect Month entry"
    },
    {
      "ID": "45",
      "Title": "Validation error",
      "Message": "Incorrect Month entry"
    },
    {
      "ID": "46",
      "Title": "Validation error",
      "Message": "Enter Year"
    },
    {
      "ID": "47",
      "Title": "Validation error",
      "Message": "Incorrect Year entry"
    },
    {
      "ID": "48",
      "Title": "Validation error",
      "Message": "Please enter valid Zipcode"
    },
    {
      "ID": "49",
      "Title": "Validation error",
      "Message": "Please enter valid Amount"
    },
    {
      "ID": "50",
      "Title": "Validation error",
      "Message": "Gift Card Verified\nYour Gift Card Balance is : <X>"
    },
    {
      "ID": "51",
      "Title": "Validation error",
      "Message": "Sorry! Amount entered is more than the purchase value.  Please check and re-enter valid amount."
    },
    {
      "ID": "52",
      "Title": "Validation error",
      "Message": "Oops! Amount entered is more than the purchase value.  Please check and re-enter valid amount."
    },
    {
      "ID": "53",
      "Title": "Validation error",
      "Message": "You will lose all sales order information if you close this window.  Do you wish to continue?"
    },
    {
      "ID": "54",
      "Title": "Validation error",
      "Message": "Only <X> of this item available.  Please choose more from other options"
    },
    {
      "ID": "55",
      "Title": "Validation error",
      "Message": "This action will end your current shift. Do you wish to sign off?"
    },
    {
      "ID": "55",
      "Title": "Validation error",
      "Message": "Are you sure you want to cancel this order?"
    },
    {
      "ID": "56",
      "Title": "Validation error",
      "Message": "You have Unserved items, Are you sure you want to pay now?"
    },
    {
      "ID": "57",
      "Title": "Validation error",
      "Message": "You cannot log in without closing or finalizing previous shift"
    },
    {
      "ID": "58",
      "Title": "Validation error",
      "Message": "Payment successful"
    },
    {
      "ID": "59",
      "Title": "Validation error",
      "Message": "Order delivered to kitchen successfully"
    },
    {
      "ID": "60",
      "Title": "Validation error",
      "Message": "This Combo ticket is sold out!! Please choose another ticket"
    },
    {
      "ID": "61",
      "Title": "Validation error",
      "Message": "Please enter valid card number and try again"
    },
    {
      "ID": "62",
      "Title": "Validation error",
      "Message": "Zipcode must be numbers!"
    },
    {
      "ID": "63",
      "Title": "Validation error",
      "Message": "You have selected the seats, click on Next to proceed"
    },
    {
      "ID": "64",
      "Title": "Validation error",
      "Message": "You can only pick up a maximum of 10 food items per transaction"
    },
    {
      "ID": "65",
      "Title": "Validation error",
      "Message": "You may not leave a single unoccupied seat in between of your selected seats. Please choose adjacent seats"
    }
  ]


  public kioskErrorMsgList = [
    {
      'id': 0,
      'message': 'No records found. Please scan QR Code again'
    },
    {
      'id': 1,
      'message': 'You maxed at 10!! Choose something else from the available items'
    },
    {
      'id': 2,
      'message': 'Currently out of stock! You might want to try other items'
    }, {
      'id': 3,
      'message': 'Please verify card. Swipe again or use another card'
    }, {
      'id': 4,
      'message': 'This GiftCard is not available. Please try using a valid GiftCard.'
    }, {
      'id': 5,
      'message': 'OOPS! Session expired. Please login again.'
    },
    {
      'id': 6,
      'message': 'These tickets are already printed. Please contact the Manager for further assistance'
    },
    {
      'id': 7,
      'message': 'OOPS! Session expired. Please login again.'
    },
    {
      'id': 8,
      'message': 'Please add tickets first!'
    },
    {
      'id': 9,
      'message': 'No ticket found for the information provided'
    },
    {
      'id': 10,
      'message': ' available. Please choose more from other combo options'
    },
    {
      'id': 11,
      'message': 'Your session will expire automatically in'
    },
    {
      'id': 12,
      'message': 'Please click on "Continue" to extend your session.'
    },
    {
      'id': 13,
      'message': 'One or more validation errors occured.'
    },
    {
      'id': 14,
      'message': 'Seat Confirmation'
    },
    {
      'id': 15,
      'message': 'This is not an actual seat but space to accomodate wheelchair. If you are not a wheelchair guest, please choose another seat.'
    },
    {
      'id': 16,
      'message': 'This seat is designed for a companion to a disabled person who is occupying the adjacent wheelchair space. If you are neither disabled nor a companion to a disabled guest, you will have to surrender your seat upon request. By acknowledging this, you are confirming that you understand.'
    },
    {
      'id': 17,
      'message': 'Validation'
    },
    {
      'id': 18,
      'message': 'Only 16 digit pin is allowed'
    },
    {
      'id': 19,
      'message': 'ERROR'
    },
    {
      'id': 20,
      'message': 'No Swipe Reader Is Attached'
    },
    {
      'id': 21,
      'message': 'Failed'
    },
    {
      'id': 22,
      'message': 'Payment declined'
    },
    {
      'id': 23,
      'message': 'This GiftCard is not sold'
    },
    {
      'id': 24,
      'message': 'Please sell first to use this giftcard'
    },
    {
      'id': 25,
      'message': 'Giftcard has no expiry date'
    },
    {
      'id': 26,
      'message': 'Giftcard is expired!'
    },
    {
      'id': 27,
      'message': 'This GiftCard has insufficient balance'
    },
    {
      'id': 28,
      'message': 'Please recharge your giftcard'
    },
    {
      'id': 29,
      'message': 'GiftCard Not Found'
    },
    {
      'id': 30,
      'message': 'Please try entering a valid giftcard number'
    },
    {
      'id': 31,
      'message': 'Seats are not available.'
    },
    {
      'id': 32,
      'message': 'OOPS..! Inventory is out of stock !'
    },
    {
      'id': 33,
      'message': 'Need to replace '
    },
    {
      'id': 34,
      'message': ' Please update your selection accordingly.'
    },
    {
      'id': 35,
      'message': 'OOPs..!'
    },
    {
      'id': 36,
      'message': 'There is some problem in processing sales order'
    },
    {
      'id': 37,
      'message': 'An error occurred while processing your request, pls try again or contact support'
    },
    {
      'id': 38,
      'message': 'Payment failed'
    },
    {
      'id': 39,
      'message': 'Error processing your card. Please try again.'
    },
    {
      'id': 40,
      'message': 'Transaction failed'
    },

    {
      'id': 41,
      'message': 'Please try again or swipe another card'
    },
    {
      'id': 42,
      'message': 'There is some problem to delete all the seat holds'
    },
    {
      'id': 43,
      'message': 'Order placed successfully'
    },
    {
      'id': 44,
      'message': 'Screen data not found!'
    },
    {
      'id': 45,
      'message': 'Some error occured!!.'
    },
    {
      'id': 46,
      'message': 'You have selected all '
    },
    {
      'id': 47,
      'message': 'You may not leave a single unoccupied seat in between of your selected seats. Please check '
    },
    {
      'id': 48,
      'message': 'Seats holds failed. Retry with different seats.'
    },
    {
      'id': 49,
      'message': 'Please select all your '
    },
    {
      'id': 50,
      'message': 'The GiftCard is not yet Sold. Please Sell to check for balance.'
    },
    {
      'id': 51,
      'message': 'GiftCard Not Found. Please try entering a valid giftcard number.'
    },
    {
      'id': 52,
      'message': 'Failed to fetch giftcard. Server not availble try again after sometime.'
    },
    {
      'id': 53,
      'message': 'This GiftCard is already added in cart. Please choose another one.'
    },
    {
      'id': 54,
      'message': 'This GiftCard is already sold'
    },
    {
      'id': 55,
      'message': 'This GiftCard is not sold'
    },
    {
      'id': 56,
      'message': 'Please choose another one'
    },
    {
      'id': 57,
      'message': 'In order to recharge please sell this giftcard first'
    },
    {
      'id': 58,
      'message': 'This GiftCard has insufficient balance. Please recharge your giftcard.'
    },
    {
      'id': 59,
      'message': 'GiftCard Not Found. Please try entering a valid giftcard number.'
    },
    {
      'id': 60,
      'message': 'Please enter member id.'
    },
    {
      'id': 61,
      'message': 'Minimum quantity should be price card combo quantity'
    },

    {
      'id': 62,
      'message': 'No shows found for selected movie!'
    },
    {
      'id': 63,
      'message': 'Some error occurred, please select another show.'
    },
    {
      'id': 64,
      'message': 'Please select atleast one show.'
    },
    {
      'id': 65,
      'message': 'No records found. Please scan QR Code again'
    },
    {
      'id': 66,
      'message': 'Tickets data not found!'
    },
    {
      'id': 67,
      'message': 'You can max purchae 10 tickets!'
    },
    {
      'id': 68,
      'message': 'Sorry, Open Show is housefull!'
    },
    {
      'id': 69,
      'message': 'Hey we can serve you only '
    },
    {
      'id': 70,
      'message': 'Cannot find recent tickets with Phone Number'
    }

  ]
  public posErrorMsgList = [
    {
      'id': 0,
      'message': 'Please enter valid CVV'
    },
    {
      'id': 1,
      'message': 'Please swipe the card'
    },
    {
      'id': 2,
      'message': 'No records found. Please verify confirmation code and try again'
    },
    {
      'id': 3,
      'message': 'Are you sure you want to swap A7 to D1?'
    },
    {
      'id': 4,
      'message': 'Are you sure you dont want to hold this seat anymore?'
    },
    {
      'id': 5,
      'message': 'You can refund to card only after 24 hours from the time of transaction.  You can refund by cash.  '
    },
    {
      'id': 6,
      'message': 'Invalid credit card number.  Choose another card or change payment mode '
    },
    {
      'id': 7,
      'message': 'Are you sure you want to swap ?'
    },
    {
      'id': 8,
      'message': "Are you sure you don't want to hold this seat anymore?"
    },
    {
      'id': 9,
      'message': "Please verify confirmation code and try again"
    },
    {
      'id': 10,
      'message': "No records found"
    },
    {
      'id': 11,
      'message': "No Active lane found"
    },
    {
      'id': 12,
      'message': "please create or associate lane"
    },
    {
      'id': 13,
      'message': "Failed"
    },
    {
      'id': 14,
      'message': "Please finalize previous day shift before opening a new shift"
    },
    {
      'id': 15,
      'message': "Oops.!!"
    },
    {
      'id': 16,
      'message': "Pin does not match"
    },
    {
      'id': 17,
      'message': "Please Associate Lane to this kiosk"
    },
    {
      'id': 18,
      'message': "SORRY..!"
    },
    {
      'id': 19,
      'message': " is out of stock"
    },
    {
      'id': 20,
      'message': "There are only "
    },
    {
      'id': 21,
      'message': " items available"
    },
    {
      'id': 22,
      'message': "Please enter appropriate value"
    },
    {
      'id': 23,
      'message': "Please fill all mandatory fields"
    },
    {
      'id': 24,
      'message': "Reason is required."
    },
    {
      'id': 25,
      'message': "Action is required"
    },
    {
      'id': 26,
      'message': "Quantity is required."
    },
    {
      'id': 27,
      'message': "This GiftCard is already added in cart"
    },
    {
      'id': 28,
      'message': "Please choose another one"
    },
    {
      'id': 29,
      'message': "This GiftCard Is Already Expired"
    },
    {
      'id': 30,
      'message': "This GiftCard has been sold already.  Choose another card to sell."
    },
    {
      'id': 31,
      'message': "No Price card found"
    },
    {
      'id': 32,
      'message': "This Show Is Already Sold You Can't Hold Seat !"
    },
    {
      'id': 33,
      'message': "No Seats Found"
    },
    {
      'id': 34,
      'message': "Request Failed"
    },
    {
      'id': 35,
      'message': "An error occurred while processing your request, pls try again or contact support"
    },
    {
      'id': 36,
      'message': "OOPS..! Inventory is out of stock !"
    },
    {
      'id': 37,
      'message': "Need to replace "
    },
    {
      'id': 38,
      'message': " Please update your selection accordingly."
    },
    {
      'id': 39,
      'message': "There are only "
    },
    {
      'id': 40,
      'message': " items available"
    },
    {
      'id': 41,
      'message': "Transaction failed"
    },
    {
      'id': 42,
      'message': "Please try again or swipe another card"
    },
    {
      'id': 43,
      'message': "This Shift has been closed"
    },
    {
      'id': 44,
      'message': "Please reopen shift to start selling"
    },
    {
      'id': 45,
      'message': "No Swipe Reader Is Attached"
    },

    {
      'id': 46,
      'message': "Please enter a valid cash amount"
    },
    {
      'id': 47,
      'message': "GiftCard validation"
    },
    {
      'id': 48,
      'message': "Please enter 16 digit pin number"
    },
    {
      'id': 49,
      'message': "This GiftCard is not sold"
    },
    {
      'id': 50,
      'message': "Please sell first to use this giftcard"
    },
    {
      'id': 51,
      'message': "This GiftCard has insufficient balance"
    },
    {
      'id': 52,
      'message': "Please recharge your giftcard"
    },
    {
      'id': 53,
      'message': "GiftCard Not Found"
    },
    {
      'id': 54,
      'message': "Please try entering a valid giftcard number"
    },
    {
      'id': 55,
      'message': "This action will End your current shift. Do you wish to sign off?"
    },
    {
      'id': 56,
      'message': "Hold Seats Error"
    },
    {
      'id': 57,
      'message': "Amount entered cannot be greater than the total sales order $"
    },
    {
      'id': 58,
      'message': "Void order"
    },
    {
      'id': 59,
      'message': "This action will void all the items in the order. Are you sure?"
    },
    {
      'id': 60,
      'message': " Cash transaction is failed<br/>"
    },

    {
      'id': 61,
      'message': " This GiftCard has insufficient balance<br/>"
    },
    {
      'id': 62,
      'message': " CC transaction is failed<br/>"
    },
    {
      'id': 63,
      'message': " Card transaction failed<br/>"
    },
    {
      'id': 64,
      'message': "Void Failed"
    },
    {
      'id': 65,
      'message': "Payment already processed. This item is not eligible for void"
    },
    {
      'id': 66,
      'message': "Please Enter Valid Amount"
    },

    {
      'id': 67,
      'message': "Please verify Gift Card"
    },
    {
      'id': 68,
      'message': "Please Enter Amount Paid by Credit Card"
    },
    {
      'id': 69,
      'message': "Please Provide Valid Card detail"
    },
    {
      'id': 70,
      'message': "Gift Card Verified"
    },
    {
      'id': 71,
      'message': "Your Gift Card Balance is - "
    },
    {
      'id': 72,
      'message': "Swipe Failed Please Try Again"
    },
    {
      'id': 73,
      'message': "Error Message"
    },
    {
      'id': 74,
      'message': "You cannot exit as there is failed transaction, Void the order to proceed."
    },
    {
      'id': 75,
      'message': "You will lose all sales order information if you close this window. Do you wish to continue?"
    },
    {
      'id': 76,
      'message': "Hey the combo ticket is out of stock. Please choose other ticket type"
    },
    {
      'id': 77,
      'message': "Hey, we have only "
    },
    {
      'id': 78,
      'message': " combo tickets available. Please choose other ticket type"
    },
    {
      'id': 79,
      'message': "Seat Confirmation"
    },
    {
      'id': 80,
      'message': "This is not an actual seat but space to accomodate wheelchair. If you are not a wheelchair guest, please choose another seat."
    },
    {
      'id': 81,
      'message': "Ticket mismatch"
    },
    {
      'id': 82,
      'message': "Request error"
    },
    {
      'id': 83,
      'message': "Refund to credit card possible only after 24 hours from transaction time. Please choose Cash option for refund."
    },
    {
      'id': 84,
      'message': "Refund amount cannot be greater than the amount charged ($"
    },
    {
      'id': 85,
      'message': "Card exceeds maximum captured amount."
    },
    {
      'id': 86,
      'message': "Cannot void as Giftcardsale is part of order. Please choose refund option for the rest of items."
    },

    {
      'id': 87,
      'message': "Please Selet reason for refunding this item"
    },
    {
      'id': 88,
      'message': "Please Enter reason for refunding this item"
    },
    {
      'id': 89,
      'message': "Please reopen shift to start refund"
    },
    {
      'id': 90,
      'message': "Refund Failed"
    },
    {
      'id': 91,
      'message': "Order is in processing state. This item is not eligible for refund"
    },
    {
      'id': 92,
      'message': "Wastage Entered successfully"
    },
    {
      'id': 93,
      'message': "Hold Seats"
    },
    {
      'id': 94,
      'message': "Hold Seats Removed"
    },
    {
      'id': 95,
      'message': "Voided Successfully"
    },
    {
      'id': 96,
      'message': "Order placed successfully"
    },
    {
      'id': 97,
      'message': "Only single movie is allowed for swaping the seats"
    },
    {
      'id': 98,
      'message': "you have selected "
    },
    {
      'id': 99,
      'message': "POS Terminal added"
    },
    {
      'id': 100,
      'message': "POS Name is required"
    },
    {
      'id': 101,
      'message': "POS Name is already exist"
    },
    {
      'id': 102,
      'message': "POS Type is required"
    },
    {
      'id': 103,
      'message': "Lane Is Required"
    },
    {
      'id': 104,
      'message': "Please Register Lane for this Location from Merchant Setup Module."
    },
    {
      'id': 105,
      'message': "POS Terminal Updated Successfull !"
    },
    {
      'id': 106,
      'message': 'Employee Starting Cash is required'
    },
    {
      'id': 107,
      'message': 'Employee PIN is required'
    },
    {
      'id': 108,
      'message': 'Inventory is Required!'
    },
    {
      'id': 109,
      'message': 'Reason is Required!'
    },
    {
      'id': 110,
      'message': 'Action is Required!'
    },
    {
      'id': 111,
      'message': 'Quantity Should be in Number'
    },
    {
      'id': 112,
      'message': 'Quantity is Required!'
    },
    {
      'id': 113,
      'message': 'Please enter name as it appears on the card'
    },
    {
      'id': 114,
      'message': 'Card Holder Name is Required'
    },
    {
      'id': 115,
      'message': 'Only numbers are allowed'
    },
    {
      'id': 116,
      'message': 'Card Number is Required.!'
    },
    {
      'id': 117,
      'message': 'Quantity should be greater than 0 '
    },
    {
      'id': 118,
      'message': 'This inventory is out of stock.'
    },
    {
      'id': 119,
      'message': "Please verify Merchant Credential to proceed"
    },
    {
      'id': 120,
      'message': "A split payment order can be refunded only in full, partial refund not possible."
    },
    {
      'id': 121,
      'message': "If the sales order has been partially refunded earlier, you cannot void rest of the items now. Please choose refund option for the rest of the items."
    },
    {
      'id': 122,
      'message': "You cannot refund/void multiple sales orders at a time. Please select items in one sales order and try again"
    },
    {
      'id': 123,
      'message': "Please check the card reader’s network connection to proceed"
    },
    {
      'id': 124,
      'message': 'No records found. Please verify email id and try again'
    },
    {
      'id': 125,
      'message': 'No records found. Please verify details and try again.'
    },
    {
      'id': 126,
      'message': 'No records found.Please verify phone number and try again'
    },
    {
      'id': 127,
      'message': 'You cannot swap seats of multiple sales orders at a time. Please select items in one sales order and try again'
    },
    {
      'id': 128,
      'message': 'No records found.Please verify card number and try again'
    },
      
    {
      'id': 129,
        'message': 'This ticket has already been printed.Do you want to print a duplicate copy?'
    }
  ]
  // public posErrorMsgList = [
  //   {
  //     'id': 0,
  //     'message': 'Please enter valid CVV'
  //   },
  //   {
  //     'id': 1,
  //     'message': 'Please swipe the card'
  //   },
  //   {
  //     'id': 2,
  //     'message': 'No records found. Please verify confirmation code and try again'
  //   },
  //   {
  //     'id': 3,
  //     'message': 'Are you sure you want to swap A7 to D1?'
  //   },
  //   {
  //     'id': 4,
  //     'message': 'Are you sure you dont want to hold this seat anymore?'
  //   },
  //   {
  //     'id': 5,
  //     'message': 'You can refund to card only after 24 hours from the time of transaction.  You can refund by cash.  '
  //   },
  //   {
  //     'id': 6,
  //     'message': 'Invalid credit card number.  Choose another card or change payment mode '
  //   },
  //   {
  //     'id': 7,
  //     'message': 'Are you sure you want to swap ?'
  //   },
  //   {
  //     'id': 8,
  //     'message': "Are you sure you don't want to hold this seat anymore?"
  //   },
  //   {
  //     'id': 9,
  //     'message': "Please verify confirmation code and try again"
  //   },
  //   {
  //     'id': 10,
  //     'message': "No records found"
  //   },
  //   {
  //     'id': 11,
  //     'message': "No Active lane found"
  //   },
  //   {
  //     'id': 12,
  //     'message': "please create or associate lane"
  //   },
  //   {
  //     'id': 13,
  //     'message': "Failed"
  //   },
  //   {
  //     'id': 14,
  //     'message': "Please finalize previous day shift before opening a new shift"
  //   },
  //   {
  //     'id': 15,
  //     'message': "Oops.!!"
  //   },
  //   {
  //     'id': 16,
  //     'message': "Pin does not match"
  //   },
  //   {
  //     'id': 17,
  //     'message': "Please Associate Lane to this kiosk"
  //   },
  //   {
  //     'id': 18,
  //     'message': "SORRY..!"
  //   },
  //   {
  //     'id': 19,
  //     'message': " is out of stock"
  //   },
  //   {
  //     'id': 20,
  //     'message': "There are only "
  //   },
  //   {
  //     'id': 21,
  //     'message': " items available"
  //   },
  //   {
  //     'id': 22,
  //     'message': "Please enter appropriate value"
  //   },
  //   {
  //     'id': 23,
  //     'message': "Please fill all mandatory fields"
  //   },
  //   {
  //     'id': 24,
  //     'message': "Reason is required."
  //   },
  //   {
  //     'id': 25,
  //     'message': "Action is required"
  //   },
  //   {
  //     'id': 26,
  //     'message': "Quantity is required."
  //   },
  //   {
  //     'id': 27,
  //     'message': "This GiftCard is already added in cart"
  //   },
  //   {
  //     'id': 28,
  //     'message': "Please choose another one"
  //   },
  //   {
  //     'id': 29,
  //     'message': "This GiftCard Is Already Expired"
  //   },
  //   {
  //     'id': 30,
  //     'message': "This GiftCard has been sold already.  Choose another card to sell."
  //   },
  //   {
  //     'id': 31,
  //     'message': "No Price card found"
  //   },
  //   {
  //     'id': 32,
  //     'message': "This Show Is Already Sold You Can't Hold Seat !"
  //   },
  //   {
  //     'id': 33,
  //     'message': "No Seats Found"
  //   },
  //   {
  //     'id': 34,
  //     'message': "Request Failed"
  //   },
  //   {
  //     'id': 35,
  //     'message': "An error occurred while processing your request, pls try again or contact support"
  //   },
  //   {
  //     'id': 36,
  //     'message': "OOPS..! Inventory is out of stock !"
  //   },
  //   {
  //     'id': 37,
  //     'message': "Need to replace "
  //   },
  //   {
  //     'id': 38,
  //     'message': " Please update your selection accordingly."
  //   },
  //   {
  //     'id': 39,
  //     'message': "There are only "
  //   },
  //   {
  //     'id': 40,
  //     'message': " items available"
  //   },
  //   {
  //     'id': 41,
  //     'message': "Transaction failed"
  //   },
  //   {
  //     'id': 42,
  //     'message': "Please try again or swipe another card"
  //   },
  //   {
  //     'id': 43,
  //     'message': "This Shift has been closed"
  //   },
  //   {
  //     'id': 44,
  //     'message': "Please reopen shift to start selling"
  //   },
  //   {
  //     'id': 45,
  //     'message': "No Swipe Reader Is Attached"
  //   },

  //   {
  //     'id': 46,
  //     'message': "Please enter a valid cash amount"
  //   },
  //   {
  //     'id': 47,
  //     'message': "GiftCard validation"
  //   },
  //   {
  //     'id': 48,
  //     'message': "Please enter 16 digit pin number"
  //   },
  //   {
  //     'id': 49,
  //     'message': "This GiftCard is not sold"
  //   },
  //   {
  //     'id': 50,
  //     'message': "Please sell first to use this giftcard"
  //   },
  //   {
  //     'id': 51,
  //     'message': "This GiftCard has insufficient balance"
  //   },
  //   {
  //     'id': 52,
  //     'message': "Please recharge your giftcard"
  //   },
  //   {
  //     'id': 53,
  //     'message': "GiftCard Not Found"
  //   },
  //   {
  //     'id': 54,
  //     'message': "Please try entering a valid giftcard number"
  //   },
  //   {
  //     'id': 55,
  //     'message': "This action will End your current shift. Do you wish to sign off?"
  //   },
  //   {
  //     'id': 56,
  //     'message': "Hold Seats Error"
  //   },
  //   {
  //     'id': 57,
  //     'message': "Amount entered cannot be greater than the total sales order $"
  //   },
  //   {
  //     'id': 58,
  //     'message': "Void order"
  //   },
  //   {
  //     'id': 59,
  //     'message': "This action will void all the items in the order. Are you sure?"
  //   },
  //   {
  //     'id': 60,
  //     'message': " Cash transaction is failed<br/>"
  //   },

  //   {
  //     'id': 61,
  //     'message': " This GiftCard has insufficient balance<br/>"
  //   },
  //   {
  //     'id': 62,
  //     'message': " CC transaction is failed<br/>"
  //   },
  //   {
  //     'id': 63,
  //     'message': " Card transaction failed<br/>"
  //   },
  //   {
  //     'id': 64,
  //     'message': "Void Failed"
  //   },
  //   {
  //     'id': 65,
  //     'message': "Payment already processed. This item is not eligible for void"
  //   },
  //   {
  //     'id': 66,
  //     'message': "Please Enter Valid Amount"
  //   },

  //   {
  //     'id': 67,
  //     'message': "Please verify Gift Card"
  //   },
  //   {
  //     'id': 68,
  //     'message': "Please Enter Amount Paid by Credit Card"
  //   },
  //   {
  //     'id': 69,
  //     'message': "Please Provide Valid Card detail"
  //   },
  //   {
  //     'id': 70,
  //     'message': "Gift Card Verified"
  //   },
  //   {
  //     'id': 71,
  //     'message': "Your Gift Card Balance is - "
  //   },
  //   {
  //     'id': 72,
  //     'message': "Swipe Failed Please Try Again"
  //   },
  //   {
  //     'id': 73,
  //     'message': "Error Message"
  //   },
  //   {
  //     'id': 74,
  //     'message': "You cannot exit as there is failed transaction, Void the order to proceed."
  //   },
  //   {
  //     'id': 75,
  //     'message': "You will lose all sales order information if you close this window. Do you wish to continue?"
  //   },
  //   {
  //     'id': 76,
  //     'message': "Hey the combo ticket is out of stock. Please choose other ticket type"
  //   },
  //   {
  //     'id': 77,
  //     'message': "Hey, we have only "
  //   },
  //   {
  //     'id': 78,
  //     'message': " combo tickets available. Please choose other ticket type"
  //   },
  //   {
  //     'id': 79,
  //     'message': "Seat Confirmation"
  //   },
  //   {
  //     'id': 80,
  //     'message': "This is not an actual seat but space to accomodate wheelchair. If you are not a wheelchair guest, please choose another seat."
  //   },
  //   {
  //     'id': 81,
  //     'message': "Ticket mismatch"
  //   },
  //   {
  //     'id': 82,
  //     'message': "Request error"
  //   },
  //   {
  //     'id': 83,
  //     'message': "Refund to credit card possible only after 24 hours from transaction time. Please choose Cash option for refund."
  //   },
  //   {
  //     'id': 84,
  //     'message': "Refund amount cannot be greater than the amount charged ($"
  //   },
  //   {
  //     'id': 85,
  //     'message': "Card exceeds maximum captured amount."
  //   },
  //   {
  //     'id': 86,
  //     'message': "Cannot void as Giftcardsale is part of order. Please choose refund option for the rest of items."
  //   },

  //   {
  //     'id': 87,
  //     'message': "Please Selet reason for refunding this item"
  //   },
  //   {
  //     'id': 88,
  //     'message': "Please Enter reason for refunding this item"
  //   },
  //   {
  //     'id': 89,
  //     'message': "Please reopen shift to start refund"
  //   },
  //   {
  //     'id': 90,
  //     'message': "Refund Failed"
  //   },
  //   {
  //     'id': 91,
  //     'message': "Order is in processing state. This item is not eligible for refund"
  //   },
  //   {
  //     'id': 92,
  //     'message': "Wastage Entered successfully"
  //   },
  //   {
  //     'id': 93,
  //     'message': "Hold Seats"
  //   },
  //   {
  //     'id': 94,
  //     'message': "Hold Seats Removed"
  //   },
  //   {
  //     'id': 95,
  //     'message': "Voided Successfully"
  //   },
  //   {
  //     'id': 96,
  //     'message': "Order placed successfully"
  //   },
  //   {
  //     'id': 97,
  //     'message': "Only single movie is allowed for swaping the seats"
  //   },
  //   {
  //     'id': 98,
  //     'message': "you have selected "
  //   },
  //   {
  //     'id': 99,
  //     'message': "POS Terminal added"
  //   },
  //   {
  //     'id': 100,
  //     'message': "POS Name is required"
  //   },
  //   {
  //     'id': 101,
  //     'message': "POS Name is already exist"
  //   },
  //   {
  //     'id': 102,
  //     'message': "POS Type is required"
  //   },
  //   {
  //     'id': 103,
  //     'message': "Lane Is Required"
  //   },
  //   {
  //     'id': 104,
  //     'message': "Please Register Lane For this Location from POS Setup Module."
  //   },
  //   {
  //     'id': 105,
  //     'message': "POS Terminal Updated Successfull !"
  //   },
  //   {
  //     'id': 106,
  //     'message': 'Employee Starting Cash is required'
  //   },
  //   {
  //     'id': 107,
  //     'message': 'Employee PIN is required'
  //   },
  //   {
  //     'id': 108,
  //     'message': 'Inventory is Required!'
  //   },
  //   {
  //     'id': 109,
  //     'message': 'Reason is Required!'
  //   },
  //   {
  //     'id': 110,
  //     'message': 'Action is Required!'
  //   },
  //   {
  //     'id': 111,
  //     'message': 'Quantity Should be in Number'
  //   },
  //   {
  //     'id': 112,
  //     'message': 'Quantity is Required!'
  //   },
  //   {
  //     'id': 113,
  //     'message': 'Please enter name as it appears on the card'
  //   },
  //   {
  //     'id': 114,
  //     'message': 'Card Holder Name is Required'
  //   },
  //   {
  //     'id': 115,
  //     'message': 'Only numbers are allowed'
  //   },
  //   {
  //     'id': 116,
  //     'message': 'Card Number is Required.!'
  //   },
  //   {
  //     'id': 117,
  //     'message': 'Quantity should be greater than 0 '
  //   },
  //   {
  //     'id': 118,
  //     'message': 'This inventory is out of stock.'
  //   },
  //   {
  //     'id': 119,
  //     'message': 'Please check the card reader’s network connection to proceed'
  //   },
  //   {
  //     'id': 120,
  //     'message': "A split payment order can be refunded only in full, partial refund not possible."
  //   },
  //   {
  //     'id': 119,
  //     'message': "Refund order"
  //   },
  // ]

  public dineInErrorMsgList = [
    {
      'id': 0,
      'message': 'You cannot log in without closing or finalizing previous shift'
    },
    {
      'id': 1,
      'message': 'Payment successful'
    },
    {
      'id': 2,
      'message': 'Order delivered to kitchen successfully'
    },
    {
      'id': 3,
      'message': 'Order placed successfully'
    },
    {
      'id': 4,
      'message': 'Oops.!!'
    },
    {
      'id': 5,
      'message': 'Pin does not match'
    },
    {
      'id': 6,
      'message': 'Please enter valid amount'
    },
    {
      'id': 7,
      'message': 'SORRY..!'
    },
    {
      'id': 8,
      'message': 'This item out of stock!!'
    },
    {
      'id': 9,
      'message': 'Are you sure you want to cancel this order?'
    },
    {
      'id': 10,
      'message': 'You have Unserved items'
    },

    {
      'id': 11,
      'message': 'Are you sure you want to pay now?'
    },
    {
      'id': 12,
      'message': 'Invalid card number!'
    },
    {
      'id': 13,
      'message': 'Swipe a valid card or change payment mode.'
    },
    {
      'id': 14,
      'message': 'Please enter name as it appears on the card'
    },
    {
      'id': 15,
      'message': 'Choose a valid card or change payment mode.'
    },
    {
      'id': 16,
      'message': 'Incorrect Month'
    },
    {
      'id': 17,
      'message': 'Enter a valid CVV!'
    },
    {
      'id': 18,
      'message': 'Enter appropriate amount'
    },
    {
      'id': 19,
      'message': 'Request Failed'
    },
    {
      'id': 20,
      'message': 'An error occurred while processing your request, pls try again or contact support'
    },
    {
      'id': 21,
      'message': "It's already paid"
    },
    {
      'id': 22,
      'message': 'This item is out of stock'
    },
    {
      'id': 23,
      'message': 'There are only  '
    },
    {
      'id': 24,
      'message': 'items available'
    },
    {
      'id': 25,
      'message': 'OOPS..! Inventory is out of stock !'
    },
    {
      'id': 26,
      'message': 'Need to replace '
    },
    {
      'id': 27,
      'message': ' Please update your selection accordingly.'
    },
    {
      'id': 28,
      'message': 'You have canceled the order successfully'
    },
    {
      'id': 29,
      'message': 'Swipe Failed Please Try Again'
    },
    {
      'id': 30,
      'message': 'ERROR'
    },
    {
      'id': 31,
      'message': 'No Swipe Reader Is Attached'
    },
    {
      'id': 32,
      'message': 'Please enter a valid cash amount'
    },
    {
      'id': 33,
      'message': 'You have Unserved items : '
    },
    {
      'id': 34,
      'message': 'Are you sure you want to pay now?'
    },
    {
      'id': 35,
      'message': 'No Active lane found'
    },
    {
      'id': 36,
      'message': 'please create or associate lane'
    },
    {
      'id': 37,
      'message': 'Failed'
    },
    {
      'id': 38,
      'message': 'Please finalize previous day shift before opening a new shift'
    },
    {
      'id': 39,
      'message': 'Invalid card number!'
    },
    {
      'id': 40,
      'message': 'Swipe a valid card or change payment mode.'
    },
    {
      'id': 41,
      'message': 'Incorrect Month entry'
    },
    {
      'id': 42,
      'message': 'Incorrect Year entry'
    },
    {
      'id': 43,
      'message': 'Month Value is Required!'
    },
    {
      'id': 44,
      'message': 'Year Value is Required!'
    },
    {
      'id': 45,
      'message': 'Cvv is Required.!'
    },
    {
      'id': 46,
      'message': 'Zipcode is Must Be numbers!'
    },
    {
      'id': 47,
      'message': 'zipcode is Required.!'
    },
    {
      'id': 48,
      'message': 'This action will End your current shift. Do you wish to continue?'
    },
    {
      'id': 49,
      'message': 'Please try again or swipe another card'
    },
    {
      'id': 50,
      'message': 'Fill Form With Right Value'
    },
    {
      'id': 51,
      'message': 'Payment has been processed successfully !'
    },
    {
      'id': 52,
      'message': 'GiftCard number should be 4 digit number'
    },
    {
      'id': 53,
      'message': 'Card Number is Required'
    },
    {
      'id': 54,
      'message': 'Only Numbers are Allowed'
    },
    {
      'id': 55,
      'message': 'Card Holder Name is Required'
    },
    {
      'id': 56,
      'message': 'Card Number is Invalid!'
    },
    {
      'id': 57,
      'message': 'You can not pay giftcard using another giftcard'
    },
    {
      'id': 58,
      'message': 'Employee Starting Cash is required'
    },
    {
      'id': 59,
      'message': 'Employee PIN is required'
    },
  ]

  public gcErrorMsgList = [
    {
      'id': 0,
      'message': 'This GiftCard is not available. Please try using a valid GiftCard.'
    },
    {
      'id': 1,
      'message': 'GiftCard Not Found. Please enter a valid giftcard number.'
    },
    {
      'id': 2,
      'message': 'Gift Card can have only numerals.Please enter a valid Gift Card number.'
    },
    {
      'id': 3,
      'message': 'Please enter a valid giftcard number'
    },
    {
      'id': 4,
      'message': 'GiftCard Not Found.'
    },
    {
      'id': 5,
      'message': 'This GiftCard is not yet sold'
    },
    {
      'id': 6,
      'message': 'Gift Card can have only numerals.'
    },
    {
      'id': 7,
      'message': 'Please enter a valid Gift Card number.'
    },
    {
      'id': 8,
      'message': 'This GiftCard has insufficient balance.'
    },
    {
      'id': 9,
      'message': 'Please use other payment option.'
    },
    {
      'id': 10,
      'message': 'Cannot recharge GiftCard with another GiftCard.  Please use other payment options.'
    },
    {
      'id': 11,
      'message': 'This card number is already taken.'
    },
    {
      'id': 12,
      'message': 'Please try again with another number.'
    },
    {
      'id': 13,
      'message': 'Error on saving gift card'
    },
    {
      'id': 14,
      'message': 'This batch cannot be uploaded as there are duplicate GiftCard numbers'
    },
    {
      'id': 15,
      'message': 'Gift Card Verified'
    },
    {
      'id': 16,
      'message': 'Your Gift Card Balance is - '
    },
    {
      'id': 17,
      'message': 'Please enter card number'
    },
    {
      'id': 18,
      'message': 'Please enter minimum 4 digit gift card number'
    },
    {
      'id': 19,
      'message': 'Card numeber already taken'
    },
    {
      'id': 20,
      'message': 'Failed to upload'
    },
    {
      'id': 21,
      'message': 'Please check excel template.'
    },
    {
      'id': 22,
      'message': 'Giftcard number is not valid. '
    },
    {
      'id': 23,
      'message': 'Amount is not valid. '
    },
    {
      'id': 24,
      'message': 'You Can Not Upload New Giftcard With balance. '
    },
    {
      'id': 25,
      'message': 'Please Enter giftcard status'
    },
    {
      'id': 26,
      'message': 'This batch cannot be uploaded as there are duplicate Gift Card numbers '
    },
    {
      'id': 27,
      'message': 'Please Remove the Duplicate Gift cards to Upload'
    },
    {
      'id': 28,
      'message': 'Failed to load file'
    },
    {
      'id': 29,
      'message': 'Error in reading file'
    },
    {
      'id': 30,
      'message': 'Oops..!'
    },
    {
      'id': 31,
      'message': 'ERROR'
    },
    {
      'id': 32,
      'message': 'No Swipe Reader Is Attached'
    },
    {
      'id': 33,
      'message': 'There is some problem in processing sales order'
    },
    {
      'id': 34,
      'message': 'Enter valid amount'
    },
    {
      'id': 35,
      'message': 'Transaction failed'
    },
    {
      'id': 36,
      'message': 'Please try again or swipe another card'
    },
    {
      'id': 37,
      'message': 'Order placed successfully'
    },
    {
      'id': 38,
      'message': 'GiftCard number should be minimum 4 digit number'
    },
    {
      'id': 39,
      'message': 'GiftCard Number is Required!'
    },
    {
      'id': 40,
      'message': 'Amount is required'
    },
    {
      'id': 41,
      'message': 'Giftcard Expiry Date is required'
    },
  ]

  public adminErrorMsgList = [
    {
      'id': 0,
      'message': 'An error occurred while processing your request, Pls contact support'
    },
    {
      'id': 1,
      'message': 'Disconnected'
    },
    {
      'id': 2,
      'message': 'OOPS! The Internet connection has been lost.'
    },
    {
      'id': 3,
      'message': 'Employee Name is required'
    },
    {
      'id': 4,
      'message': 'StartDate must be current or future datetime'
    },
    {
      'id': 5,
      'message': 'EndDateTime cannot be behind the StartDateTime'
    },
    {
      'id': 6,
      'message': 'Please Choose Time within your Schedule'
    },
    {
      'id': 7,
      'message': 'Please Enter Reason For Updating Schedule'
    },
    {
      'id': 8,
      'message': 'Are you sure?'
    },
    {
      'id': 9,
      'message': 'Schedule already present for the given time range. Please provide new time range.'
    },
    {
      'id': 10,
      'message': 'Error on saving schedule'
    },

    {
      'id': 11,
      'message': 'No error message'
    },
    {
      'id': 12,
      'message': 'Are you sure you want to cancel?'
    },
    {
      'id': 13,
      'message': 'You have not clocked out from your previously scheduled shift. Please contact Manager.'
    },
    {
      'id': 14,
      'message': "You Can't Choose future time!"
    },
    {
      'id': 15,
      'message': "Invalid pin. Please try again."
    },
    {
      'id': 16,
      'message': 'Error on saving schedule'
    },
    {
      'id': 17,
      'message': 'Schedule already present for the given time range.'
    },
    {
      'id': 18,
      'message': 'Name is required'
    },
    {
      'id': 19,
      'message': 'Address is required'
    },
    {
      'id': 20,
      'message': 'Country is required'
    },
    {
      'id': 21,
      'message': 'City is required'
    },
    {
      'id': 22,
      'message': 'Timezone is required'
    },
    {
      'id': 23,
      'message': 'State is required'
    },
    {
      'id': 24,
      'message': 'Zipcode is required'
    },
    {
      'id': 25,
      'message': 'Incorrect Email format'
    },
    {
      'id': 26,
      'message': 'Are you sure you want to cancel?'
    },
    {
      'id': 27,
      'message': 'Please fill out this field'
    },
    {
      'id': 28,
      'message': 'Add more privileges to existing employee ?'
    },
    {
      'id': 29,
      'message': 'This action will close days for all shows of '
    },
    {
      'id': 30,
      'message': 'This show has tickets sold, you cannot Close show.'
    },
    {
      'id': 31,
      'message': 'Show detail updated'
    },
    {
      'id': 32,
      'message': 'Are you sure you want to delete this entry?'
    },
    {
      'id': 33,
      'message': 'Entry deleted successfully'
    },
    {
      'id': 34,
      'message': "You can't Create show with The Similar TimeSlot!"
    },
    {
      'id': 35,
      'message': "You can't Create Show with the Past Time!"
    },
    {
      'id': 36,
      'message': 'Price Card is not valid for this time slot'
    },
    {
      'id': 37,
      'message': 'Sales Channel is required'
    },
    {
      'id': 38,
      'message': 'If you leave the movie schedule before saving, you will lose changes you made. Save now. '
    },
    {
      'id': 39,
      'message': 'An error occurred while processing your request, pls try again or contact support.'
    },
    {
      'id': 40,
      'message': 'This tax cannot be inactivated as it is assigned to active items. '
    },
    {
      'id': 41,
      'message': 'Please enter a valid value. The two nearest valid values are x.xx and y.yy'
    },
    {
      'id': 42,
      'message': 'Inactive Yes/No'
    },
    {
      'id': 43,
      'message': 'Active Yes/No'
    },
    {
      'id': 44,
      'message': 'Please select Valid Days'
    },
    {
      'id': 45,
      'message': 'Please choose valid time slots. Start Time and End Time cannot be the same.'
    },
    {
      'id': 46,
      'message': 'Error. For Complimentary Ticket Price Should be 0'
    },
    {
      'id': 47,
      'message': 'Error. For Ticket Price Cannot be 0'
    },
    {
      'id': 48,
      'message': 'Email already exsits!'
    },
    {
      'id': 49,
      'message': 'Add more privileges to existing employee ID? Roles and permissions can be assigned as necessary for this location.'
    },
    {
      'id': 50,
      'message': 'Serve Error'
    },
    {
      'id': 51,
      'message': 'Error on saving schedule'
    },
    {
      'id': 52,
      'message': 'Schedule already present for the given time range. Choose different time range for another schedule'
    },
    {
      'id': 53,
      'message': 'Request error'
    },
    {
      'id': 54,
      'message': 'Schedules are overlapped for one or more employees'
    },
    {
      'id': 55,
      'message': 'Schedule already present for the given time range. Please provide new time range'
    },
    {
      'id': 56,
      'message': "You Can't Choose future time!"
    },
    {
      'id': 57,
      'message': 'Please Choose Time within your Schedule'
    },

    {
      'id': 58,
      'message': 'In Time Should be Larger than previous out time!'
    },
    {
      'id': 59,
      'message': 'Out Time Should be Larger than In time!'
    },
    {
      'id': 60,
      'message': 'Out Time Should be Less than next In time!'
    },
    {
      'id': 61,
      'message': 'Enable your location from browser settings!'
    },
    {
      'id': 62,
      'message': 'Select Out Time For Previous In!'
    },
    {
      'id': 63,
      'message': 'Select In Time First!'
    },
    {
      'id': 64,
      'message': 'Failed'
    },
    {
      'id': 65,
      'message': 'Select In Time First!'
    },
    {
      'id': 66,
      'message': 'You have registered successfully.'
    },
    {
      'id': 67,
      'message': 'There was some error while registering. Please try again later.'
    },
    {
      'id': 68,
      'message': 'Congratulations!!'
    },
    {
      'id': 69,
      'message': 'You have successfully registered with Cinema360.  We have sent you an email with further instructions.'
    },
    {
      'id': 70,
      'message': 'Employee Name is required'
    },
    {
      'id': 71,
      'message': ' Employee Duty is required'
    },
    {
      'id': 72,
      'message': 'Start Date is required'
    },
    {
      'id': 73,
      'message': ' StartDate must be current or future datetime'
    },
    {
      'id': 74,
      'message': ' EndDate cannot be earlier than the StartDate'
    },
    {
      'id': 75,
      'message': ' End Date is required'
    },
    {
      'id': 76,
      'message': 'Are you sure you want to delete this Schedule?'
    },
    {
      'id': 77,
      'message': '\n Do you wish to continue?'
    },
    {
      'id': 78,
      'message': 'To Date cannot be earlier than the From Date'
    },
    {
      'id': 79,
      'message': 'To add this item to Inventory, please enter quantity received.'
    },
    {
      'id': 80,
      'message': 'Runtime is updated. Fix the movie schedule based on new runtime.'
    },
    {
      'id': 81,
      'message': 'You cannot move this show as you have tickets sold for this show and there is another show scheduled'
    },
    {
      'id': 82,
      'message': "You can't Drag Show with The Past DateTime!"
    },
    {
      'id': 83,
      'message': "You cannot delete this show as it has tickets sold"
    },
    {
      'id': 84,
      'message': "This action will delete all selected shows.\n Do you wish to continue?"
    },
    {
      'id': 85,
      'message': "This action will delete"
    },
    {
      'id': 86,
      'message': "You can't Create Show with This duration because it's already exist !"
    },
    {
      'id': 87,
      'message': "Trailer time/clean up time is reduced.\n Do you wish to continue?"
    },
    {
      'id': 88,
      'message': "There is already a show happening at this time.  Please alter your showtime."
    },
    {
      'id': 89,
      'message': "You can't Select Both Show Information 2D And 3D At same time !"
    },
    {
      'id': 90,
      'message': "you cannot perform this Action because price card is missing"
    },
    {
      'id': 91,
      'message': "Do you wish to save the current schedule?"
    },
    {
      'id': 92,
      'message': "This action will open day all shows for the selected date.  Do you wish to continue?"
    },
    {
      'id': 93,
      'message': "No Movie Exist To be Copied!"
    },
    {
      'id': 94,
      'message': "Trailer time/clean up time is reduced and it's affected in all selected show.\n Do you wish to continue?"
    },
    {
      'id': 95,
      'message': "YOU HAVE NOT COMPLETED YOUR SHIFT HOURS. \n DO YOU WANT TO CLOCK OUT?"
    },
    {
      'id': 96,
      'message': "Please Add Ticket FIrst"
    },
    {
      'id': 97,
      'message': "You cannot make this price card inactive as there are active items associated."
    },
    {
      'id': 98,
      'message': "You cannot inactivate this channel as there are active associated items"
    },
    {
      'id': 99,
      'message': "Seat Limit"
    },
    {
      'id': 100,
      'message': "Only max 100 seats are allowed to add"
    },
    {
      'id': 101,
      'message': "This action will End current shift. Do you wish to continue?"
    },
    {
      'id': 102,
      'message': "For Complimentary Ticket Price Should be 0"
    },
    {
      'id': 103,
      'message': "For Ticket Price Cannot be 0"
    },
    {
      'id': 104,
      'message': "Email format is wrong"
    },
    {
      'id': 105,
      'message': "Email is required"
    },
    {
      'id': 106,
      'message': "Phone number format is wrong"
    },
    {
      'id': 107,
      'message': "Phone Number is required"
    },
    {
      'id': 108,
      'message': "Please select a type"
    },
    {
      'id': 109,
      'message': "Please upload your company's logo"
    },
    {
      'id': 110,
      'message': "Please check the Terms and Conditions."
    },
    {
      'id': 111,
      'message': "Postal Code is required "
    },
    {
      'id': 112,
      'message': "Total price cannot be 0. Select items and add price to them. "
    },
    {
      'id': 113,
      'message': "Select at least one item from the list."
    },
    {
      'id': 114,
      'message': "Total price Should be 0 for Complimentary."
    },
    {
      'id': 115,
      'message': "Please enter a Quantity for the selected Food !"
    },
    {
      'id': 116,
      'message': "Total Quantity for selected Food Item cannot be 0"
    },
    {
      'id': 117,
      'message': "Please enter a Quantity for the selected Inventory !"
    },
    {
      'id': 118,
      'message': "Total Quantity for selected Inventory Item cannot be 0"
    },
    {
      'id': 119,
      'message': "Sales Channel is Required"
    },
    {
      'id': 120,
      'message': "Please select a Concession Category"
    },
    {
      'id': 121,
      'message': "Please select Complimentary Yes/No !"
    },
    {
      'id': 122,
      'message': "Please select a Tax"
    },
    {
      'id': 123,
      'message': "Please select Display Order"
    },
    {
      'id': 124,
      'message': "Description is Required"
    },
    {
      'id': 125,
      'message': "First Name is required"
    },
    {
      'id': 126,
      'message': "Last Name is required"
    },
    {
      'id': 127,
      'message': "Employee Feature Name is required"
    },
    {
      'id': 128,
      'message': "Please enter Feature Description"
    },
    {
      'id': 129,
      'message': "Employee Role Name is required"
    },
    {
      'id': 130,
      'message': "Please enter Role Description"
    },
    {
      'id': 131,
      'message': "Start Datetime is required"
    },
    {
      'id': 132,
      'message': "End DateTime cannot be behind the StartDateTime"
    },
    {
      'id': 133,
      'message': "End Datetime is required"
    },
    {
      'id': 134,
      'message': "Food Name is required"
    },
    {
      'id': 135,
      'message': "Please enter Food Description"
    },
    {
      'id': 136,
      'message': "SKU number is required"
    },
    {
      'id': 137,
      'message': "Title is required"
    },
    {
      'id': 138,
      'message': "Supplier is required"
    },
    {
      'id': 139,
      'message': "Brand is required"
    },
    {
      'id': 140,
      'message': "Description is required"
    },
    {
      'id': 141,
      'message': "Purchase Price/Unit is required"
    },
    {
      'id': 142,
      'message': "Sellable is required"
    },
    {
      'id': 143,
      'message': "Total Quantity in Hand is required"
    },
    {
      'id': 144,
      'message': "Total Sold is required"
    },
    {
      'id': 145,
      'message': "Total Damaged is required"
    },
    {
      'id': 146,
      'message': "Total items in Case is required"
    },
    {
      'id': 147,
      'message': "Storage Room Number is required"
    },
    {
      'id': 148,
      'message': "Expiry Date is required"
    },
    {
      'id': 149,
      'message': "Barcode Id is required"
    },
    {
      'id': 150,
      'message': "Please select a receive date"
    },
    {
      'id': 151,
      'message': "Please enter order no"
    },
    {
      'id': 152,
      'message': "Please select a supplier"
    },
    {
      'id': 153,
      'message': "Please select a date"
    },
    {
      'id': 154,
      'message': "Please remove these inventory items from the sales channels before you can mark it Non-Sellable"
    },
    {
      'id': 155,
      'message': "Item quantity can not be more than stock in hand"
    },
    {
      'id': 156,
      'message': "There are no movies available"
    },
    {
      'id': 157,
      'message': "No Movies Found"
    },
    {
      'id': 158,
      'message': "You are not authorized to access this page."
    },
    {
      'id': 159,
      'message': "There are no screens available"
    },
    {
      'id': 160,
      'message': "No Screen Found"
    },
    {
      'id': 161,
      'message': "Show Copied succeed"
    },
    {
      'id': 162,
      'message': "Copy Days was successful"
    },
    {
      'id': 163,
      'message': "From Date is Invalid"
    },
    {
      'id': 164,
      'message': "To Date is Invalid"
    },
    {
      'id': 165,
      'message': "Compliment is required"
    },
    {
      'id': 166,
      'message': "Trailer Duration is required"
    },
    {
      'id': 167,
      'message': "Cleanup Duration is required"
    },
    {
      'id': 168,
      'message': "Rentrack is required"
    },
    {
      'id': 169,
      'message': "Location added Successfully. Contact your Company Admin to get privelages to this Location"
    },
    {
      'id': 170,
      'message': "Location Name is required"
    },
    {
      'id': 171,
      'message': "Merchant ID is required"
    },
    {
      'id': 172,
      'message': "Merchant Password is required"
    },
    {
      'id': 173,
      'message': "Merchant Name is required"
    },
    {
      'id': 174,
      'message': "Acceptor Id is required"
    },
    {
      'id': 175,
      'message': "LaneId is required"
    },
    {
      'id': 176,
      'message': "Description is required"
    },
    {
      'id': 177,
      'message': "TerminalId is required"
    },
    {
      'id': 178,
      'message': "Activation Code is required"
    },
    {
      'id': 179,
      'message': "Market Code is required"
    },
    {
      'id': 180,
      'message': "Contactless Msd Enabled"
    },
    {
      'id': 181,
      'message': "Contactless Emv Enabled"
    },
    {
      'id': 182,
      'message': "Quick Chip Enabled"
    },
    {
      'id': 183,
      'message': "Quick Chip Data Lifetime is required"
    },
    {
      'id': 184,
      'message': "Movie Name is required"
    },
    {
      'id': 185,
      'message': "Release Date is required"
    },
    {
      'id': 186,
      'message': "Display Name is required"
    },
    {
      'id': 187,
      'message': "Movie Added successfully"
    },
    {
      'id': 188,
      'message': "Combo should have a TicketType associated with it"
    },
    {
      'id': 189,
      'message': "PriceCard need defined TicketTypes"
    },
    {
      'id': 190,
      'message': "Price Card Name is Required"
    },
    {
      'id': 191,
      'message': "Ticket Price is required"
    },
    {
      'id': 192,
      'message': "Ticket Tax is required"
    },
    {
      'id': 193,
      'message': "Combo Name is required"
    },
    {
      'id': 194,
      'message': "You have unsave changes want leave?"
    },
    {
      'id': 195,
      'message': "Screen Name is required"
    },
    {
      'id': 196,
      'message': "Screen Number is required"
    },
    {
      'id': 197,
      'message': "Screen Information is required"
    },
    {
      'id': 198,
      'message': "Number of seats is required"
    },
    {
      'id': 199,
      'message': "Ticket Name is required"
    },
    {
      'id': 200,
      'message': "Ticket Display Name is required"
    },
    {
      'id': 201,
      'message': "Please select a Tax"
    },
    {
      'id': 202,
      'message': "Please select Complimentary Yes/No !"
    },
    {
      'id': 203,
      'message': "Please select a Prep Area"
    },
    {
      'id': 204,
      'message': "Please select a Subcategory"
    },
    {
      'id': 205,
      'message': "Please make sure there are no shows scheduled at the same time slot between copy day and destination day.  You cannot copy even if there is one show time conflict."
    },
    {
      'id': 206,
      'message': "You have an associated Concession in an active state and you may have an active Price Card too.  Continuing will make the Concession and Price Card inactive too."
    },
    {
      'id': 207,
      'message': "You cannot make this inventory inactive as the associated price card has been assigned in Movie Schedule, Please remove the price card from the respective show and then make this inventory inactive."
    },
    {
      'id': 208,
      'message': "The associated Inventory for this concession item is in inactive state.  Please activate Inventory before you activate Concession item."
    },
    {
      'id': 209,
      'message': "You have an associated Price Card in an active state.  Continuing will make the Price Card inactive too."
    },
    {
      'id': 210,
      'message': "You cannot make this Concession inactive as there are active Price Card/s associated"
    },
    {
      'id': 211,
      'message': "The associated Concession for this Price card is in inactive state. Please activate Concession before you activate Price Card item."
    },
    {
      'id': 212,
      'message': "Cannot add a movie if studio name is not available.  Please edit the Movie to add the studio"
    },
    {
      'id': 213,
      'message': "This name already exists!! Please provide a different name."
    },
    {
      'id': 214,
      'message': "You have updated the Company information successfully"
    },
    {
      'id': 215,
      'message': "Please verify the email credentials and provide a unique email ID that is not part of any other company to proceed"
    },
    {
      'id': 216,
      'message': "A maximum of 5 banners is already in place. If you want to add this banner, please deactivate one of the existing banners and try again."
    },
    {
      'id': 217,
      'message': "Email sent to all users in the Schedule."
    },
    {
      'id': 218,
      'message': "This email address is already registered with another company. Please provide unique email ID to proceed."
    },
    {
      'id': 219,
      'message': "No employee schedule for the date range."
    },
    {
      'id': 220,
      'message': "This Price Card will not apply for all the shows due to time conflict. Do you want to continue applying Price Card for applicable shows?."
    },
    {
      'id': 221,
      'message': "You can't select Show with the Past Time!"
    },
    {
      'id': 222,
      'message': "This Price Card will not apply for all the shows due to time conflict"
    },
    {
      'id': 223,
      'message': "This Tailer Duration & Cleanup Time will not apply for all the shows due to time conflict"
    },
    {
      'id': 224,
      'message': "You cannot make this Ticket Type inactive as the associated price card has been assigned in Movie Schedule, Please remove the price card from the respective show and then make this Ticket Type inactive."
    },
    {
      'id': 225,
      'message': "You cannot edit this Price Card as there are active shows associated."
    },
    {
      'id': 226,
      'message': "You cannot make this ticket type inactive as there are active items associated."
    },
    {
      'id': 227,
      'message': "This schedule cannot be copied as destination date already has same show times."
    },
    {
      'id': 228,
      'message': "Some shows cannot be copied as there are show time conflicts. Click on Yes if you still want to copy schedule."
    },
    {
      'id': 229,
      'message': "Please upload jpg, jpeg, or png file."
    },
    {
      'id': 230,
      'message': "This show has Tickets sold, you cannot change status to planned."
    },
    {
      'id': 231,
      'message':"Screen Number already exist"
    },
    {
      'id': 232,
      'message': "Combo Loyalty Points is required"
    },
    {
      'id': 233,
      'message': "Ticket Loyalty Points is required"
    },
    {
      'id': 234,
      'message': "Loyalty Points is required"
    },
    {
      'id': 235,
      'message': "Loyalty Points Per Dollar is required"
    },
    {
      'id': 236,
      'message': "Default Loyalty Points is required"
    },
    {
      'id': 237,
      'message': "Low Inventory is required"
    },
    {
      'id': 238,
      'message': "Preferred Stock Level is required"
    },
    {
      'id': 239,
      'message': "Please select UOM for the selected Inventory !"
    },    
    {
    'id': 240,
    'message': 'UOM is required'
    },
    {
      'id': 241,
      'message': 'Lms/Tms not available for this location'
    },
    {
      'id': 242,
      'message': 'Are you sure you want to delete this seat?'
    },
    {
      'id': 243,
      'message': 'Are you sure you want to delete this row?'
    },
    {
      'id': 244,
      'message': 'Date range cannot be more than 31 days'
    },
    {
      'id': 245,
      'message': 'To add this item to Inventory, please enter unit cost'
    },
    {
      'id': 246,
      'message': 'Please select the storage room for the inventory'
    },
    {
      'id': 247,
      'message': "Please enter a Price for the selected Food !"
    },
    {
      'id': 248,
      'message': "Price for selected Food Item cannot be 0"
    },
    {
      'id': 249,
      'message': "Please enter a Price for the selected Inventory !"
    },
    {
      'id': 250,
      'message': "Price for selected Inventory Item cannot be 0"
    },
    {
      'id': 251,
      'message': "This movie is existing. Do you want to add it again?"
    },
    {
      'id': 252,
      'message': "Maximum 9 movies can be promoted. Please deselect any of the previously promoted movies to proceed."
    },
    {
      'id': 253,
      'message': "Movie without wallpaper can not be promoted"
    },
    {
      'id': 254,
      'message': "Parking Lot Number already exist"
    },
    {
      'id': 255,
      'message': "Parking Lot Number is required"
    },
  ]

  public reportErrorMsgList = [
    {
      'id': 0,
      'message': 'An error occurred while processing your request, Pls contact support'
    },
    {
      'id': 1,
      'message': 'Generate report with out providing Date'
    },
    {
      'id': 2,
      'message': 'Generate report entering text for date'
    }, {
      'id': 3,
      'message': 'Generate report with out providing To'
    }, {
      'id': 4,
      'message': 'Generate report with out providing From Date'
    }, {
      'id': 5,
      'message': 'Generate report with To date is lesser than From date'
    },
    {
      'id': 6,
      'message': 'Generate report entering text for To date'
    },
    {
      'id': 7,
      'message': 'Generate report entering text for From date'
    },
    {
      'id': 8,
      'message': 'Enter From date later than to date'
    }

  ]

  public merchantErrorMsgList = [
    {
      'id': 0,
      'message': 'Merchant credentials are active for this location. Do you want to deactivate the current settings?'
    },
    {
      'id': 1,
      'message': 'Changing mode, it will delete all the lanes associated with POS. Are you sure you want to continue?'
    },
    {
      'id': 2,
      'message': 'Merchant lanes are active for this location. Do you want to deactivate the current settings?'
    }
  ]
}
